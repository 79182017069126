import React from 'react';
import { Link } from 'gatsby';
import LogoSvg from '../LogoSvg';
import { BoltColor } from '../../constants';

import * as styles from './projectHeader.module.css';
import LogoGradient from '../LogoGradient';

const ProjectHeader = () => {
  return (
    <header className={styles.wrap}>
      <Link to={'/'}>
        {/* <LogoSvg height={100} EColor="#ffffff" BoltColor={BoltColor} /> */}
        <LogoGradient height={100} EColor="#ffffff" />
      </Link>
    </header>
  );
};

export default ProjectHeader;
