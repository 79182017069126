import React from 'react';

const LogoGradient = ({
  width = 65,
  height,
  EColor = '#000000',
  gradient1 = '#FF7A46',
  gradient2 = '#FFC600',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 63 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47 35H10V157H47.5L54 142H24.5V102H44V88.5H24.5V49.5H52.5L47 35Z"
        fill={EColor}
        stroke={EColor}
      />
      <path
        d="M49.5 39C49.5 39 61.0088 1 61.5088 1C62.0088 1 44.5088 117.5 44.5088 117.5L36 103H44.5L44.5088 88H29.0088C29.0088 88 1.50879 172.5 1.00879 173C0.508789 173.5 21.5088 50.5 21.5088 50.5L38.5088 74L45.5088 50.5H53.5088L49.5 39Z"
        fill="url(#gradient1)"
        stroke="url(#gradient2)"
      />
      <defs>
        <linearGradient
          id="gradient1"
          x1="31.2597"
          y1="1"
          x2="31.2597"
          y2="173.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={gradient1} />
          <stop offset="1" stop-color={gradient2} />
        </linearGradient>
        <linearGradient
          id="gradient2"
          x1="31.2597"
          y1="1"
          x2="31.2597"
          y2="173.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={gradient1} />
          <stop offset="1" stop-color={gradient2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoGradient;
